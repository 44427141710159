// JavaScript source code
import "./styles/GameImageHeader.css";
import { useState, useRef, useEffect } from 'react';

function GameImageHeader() {
    const [currIndex, setCurrIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const backImgs = require.context('./GameBackgrounds', true);
    const imgList = backImgs.keys().map(image => backImgs(image));
    const newBackgroundImage = useRef(imgList[currIndex]);
    const animationState = useRef();

    useEffect(() => {
        if (refresh === true) setRefresh(false);
    }, [refresh]);

    function onValueChange(event) {
        //console.log("New Value");
        if (animationState.current === "fadeOut") {
            setNextIndex(parseInt(event.target.value));
            return;
        }
        animationState.current = "fadeOut";

        setNextIndex(parseInt(event.target.value));
    }

    function onFadeAnimationChange() {
        //console.log("Anim ended");
        if (animationState.current === "fadeIn") {
            animationState.current = "fadeOut";
        }
        else if (animationState.current === "fadeOut") {
            animationState.current = "fadeIn";
            setCurrIndex(nextIndex);
            setRefresh(true);
        }
    }

    return (
        <div>
            <div className="titleLineBackground" style={{
                background: `radial-gradient(82.17% 74.52% at 65.03% 46.8%, rgba(34, 40, 48, 0) 0%, rgba(34, 40, 48, 0.96) 83.02%), no-repeat center url("` + imgList[currIndex] + `")`,
            }} />
            <div className={"titleLineBackgroundAnim " + animationState.current} onAnimationEnd={onFadeAnimationChange} />
            <div className="gameImageSelectors">
                <form className="imageSelectorsForm" >
                    {refresh === false && imgList.map((image, index) => (
                        <input className="coloredradio" onClick={onValueChange} type="radio" id="image1" name="image" value={index} checked={index === nextIndex} />
                    ))}
                </form>
            </div>
        </div>
    );
}

export default GameImageHeader;
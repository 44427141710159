// JavaScript source code
import './styles/HomePage.css';
import { useSearchParams } from "react-router-dom";
import { ContactUsForm } from './ContactUs';
//import background from '.GameBackgrounds/SampleImageResized.png'
import { useState, useRef } from 'react';
//import sampleImage from '/public/SampleImage.png';
import GameImageHeader from './GameImageHeader';
import NewsletterSignUp from './NewsletterSignUp';
import PopupMessageContainer from './PopupAlerts/PopupMessageContainer.js';
import { GetAuthStatus } from './Utilities';
import Cookies from 'js-cookie';

function UXHomePage() {
    const [queryParams] = useSearchParams();
    const [seenPopup, setSeenPopup] = useState(Cookies.get("SeenPopup") === 'true');

    function onGetStartedClick() {
        window.location.assign("/login?page=signUp")
    }

    return (
        <div className="homePage">
            {seenPopup !== true && <PopupMessageContainer />}
            <div className="titleLine">
                <GameImageHeader />
                <div className="topBody">
                    <div className="info">
                        <h1 className="title">Ludare </h1> <br />
                        <h2 className="tagline">VOTE WITH YOUR WALLET</h2>
                        <p className="subTagLine">and support your favorite indies financially<br />so they can continue to make games you love!</p>
                        <button className="startButton" onClick={onGetStartedClick}>Get Started!</button>
                    </div>
                </div>
            </div>
            <div className="companyDesc">
                <img className="studioImage" src="/LudareLogoTransparent.png" alt="Ludare Logo" />
                <div className="descText">
                    <p className="descTitle">Who Are We?</p>
                    <p className="descDesc">Ludare is a game subscription service meant to support indie developers based on the way you play their games. This helps players support in the ways they already love, by playing games, and developers be rewarded for doing what they like, developing and maintaining great games.</p>
                </div>
            </div>

            <div className="roleSelection">
                <h1 className="roleQuestion">Who Are You?</h1>
                <div className="roleTiles">
                    <div className="playerTile">
                        <div className="playerTileTitle">
                            <img className="playerTileImage" src="/gamepad-recolored.png" alt="Gamepad Icon" />
                            <p className="playerTileTitleText">PLAYER</p>
                        </div>
                        <p className="playerTileText">For players, all you need to do is sign up, set up your payment, and then boot up any Ludare enabled game. We will automatically track your playtime during the month and then pay out your payments to the developers of the games you played.</p>
                        <a className="playerTileSelect" href="/players">I am a player!</a>
                    </div>
                    <div className="developerTile">
                        <div className="developerTileTitle">
                            <img className="developerTileImage" src="/developer-recolored.png" alt="Game Developer Icon" />
                            <p className="developerTileTitleText">DEVELOPER</p>
                        </div>
                        <p className="developerTileText">For developers, we offer a simple plugin for major engines and common languages. Once integrated and maintained in the game, this plugin will track player account information and play habits, ensuring you earn money for every minute of playtime.</p>
                        <a className="developerTileSelect" href="/studios">I am a developer!</a>
                    </div>
                </div>
            </div>

            <div className="newsletterSignUp">
                <NewsletterSignUp />
            </div>
        </div>
    );
}

export default UXHomePage;